import { atom } from 'jotai';
import { atomWithSuspenseQuery } from 'jotai-tanstack-query';
import { EventWithRels, Game } from '@statsbomb/parachute-types';
import { convertEventDataIntoDescriptions, processEventDataAtom } from '@/utils/atoms/eventData';
import { getVideoIdsFromEvents } from '@/utils/video';
import { convertFilterParamsToString, gameEventParams } from '@/utils/api';
import { unwrap } from 'jotai/utils';
import { getFormattedGameName } from '@/utils/games';
import { getIsDateBeforeOrEqualToToday } from '@/utils/date';
import { selectedGameIdForVideosAtom } from '../video';
import { fetchClientAtom } from './client';
import { gameIdAtom } from '../game/game';
import { unwrappedSelectedCycleAtom } from './cycles';
import { selectedTeamIdsAtom } from '../filters/highLevel/teams';

const rawGameGoalEventsAtom = atomWithSuspenseQuery(get => {
  const queryKey = ['gameGoalEvents', get(selectedGameIdForVideosAtom)] as const;

  const queryFn = async ({ queryKey: [, gameId] }: { queryKey: typeof queryKey }) => {
    if (!gameId) return [];
    const { fetch } = get(fetchClientAtom);

    return (await fetch(
      `/metric/goals_and_own_goals/events${convertFilterParamsToString(gameEventParams(gameId))}`,
    )) as Promise<EventWithRels[]>;
  };

  return { queryKey, queryFn };
});

export const gameGoalsEventVideoIdsAtom = atom(async get => {
  const processedEventData = processEventDataAtom(await get(rawGameGoalEventsAtom));

  return getVideoIdsFromEvents(processedEventData);
});

export const gameGoalEventDescriptionsAtom = atom(async get => {
  const rawGameGoalEvents = await get(rawGameGoalEventsAtom);
  return convertEventDataIntoDescriptions(rawGameGoalEvents.data);
});

export const gamesBySelectedCycleAtom = atomWithSuspenseQuery(get => {
  const queryKey = ['gamesData', get(unwrappedSelectedCycleAtom)] as const;

  const queryFn = async ({ queryKey: [_, selectedCycle] }: { queryKey: typeof queryKey }) => {
    const { fetch } = get(fetchClientAtom);

    if (!selectedCycle) return [];

    return (await fetch(
      `/games${convertFilterParamsToString({
        eq: { cycle_id: selectedCycle.cycle_id },
      })}`,
    )) as Game[];
  };

  return { queryKey, queryFn };
});

const rawSelectedGameAtom = atomWithSuspenseQuery(get => {
  const queryKey = ['selectedGameData', get(gameIdAtom)] as const;

  const queryFn = async ({ queryKey: [_, gameId] }: { queryKey: typeof queryKey }) => {
    const { fetch } = get(fetchClientAtom);
    if (!gameId) return null;

    const games = (await fetch(
      `/games${convertFilterParamsToString({
        limit: 1,
        eq: { game_id: gameId },
      })}`,
    )) as Game[];

    const selectedGame = games[0];
    return selectedGame || null;
  };

  return { queryKey, queryFn };
});

export const selectedGameAtom = atom(async get => (await get(rawSelectedGameAtom)).data);

export const unwrappedSelectedGameAtom = unwrap(selectedGameAtom, prev => prev || null);

export const selectedGameNameAtom = atom(async get => {
  const selectedGame = await get(selectedGameAtom);
  return selectedGame?.name || '';
});

export const unwrappedSelectedGameNameAtom = unwrap(selectedGameNameAtom, prev => prev || '');

export const gameTitleAtom = atom(async get => {
  const selectedGame = await get(selectedGameAtom);

  if (selectedGame === null) return '';
  return getFormattedGameName(selectedGame);
});

export const selectedGameIdAtom = atom<number | null>(null);

export const gamesFilteredByTeamsAndDateAtom = atom(async get => {
  const gamesByCycle = (await get(gamesBySelectedCycleAtom)).data;
  const selectedTeamIds = get(selectedTeamIdsAtom);

  const gamesFilteredByTeamsAndDate = gamesByCycle.filter(game => {
    const doesGameIncludeSelectedTeams =
      selectedTeamIds.includes(game.home_team_id) || selectedTeamIds.includes(game.away_team_id);

    const isGameDateBeforeOrEqualToToday = getIsDateBeforeOrEqualToToday(game.date);

    return doesGameIncludeSelectedTeams && isGameDateBeforeOrEqualToToday;
  });

  return gamesFilteredByTeamsAndDate;
});
