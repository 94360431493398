import { defaultSeasonsAtom } from '@/atoms/filters/highLevel/seasons';
import { playerGameAggsAtom } from '@/atoms/queries/player/playerGameAggData';
import { tableColumnsAtom } from '@/atoms/queries/tableColumns';
import { unwrappedVisibleTableColumnsAtom } from '@/atoms/table';
import { hasVideoLicenceAtom } from '@/atoms/user';
import { GeneralErrorBoundaryFallback } from '@/components/errorBoundary/ErrorBoundaryFallback';
import { GameAggregatesTable } from '@/components/tables/GameAggregatesTable';
import { PLAYER_GAMES_DEFAULT_COLUMNS } from '@/components/tables/GameAggregatesTable.constants';
import { ErrorBoundary } from '@sentry/react';
import { useAtomValue } from 'jotai';
import { Suspense } from 'react';

const PlayerGameAggsTableWithData = () => {
  const data = useAtomValue(playerGameAggsAtom);
  const hasDefaultSeason = useAtomValue(defaultSeasonsAtom);
  const isVideoColumnVisible = useAtomValue(hasVideoLicenceAtom);
  const tableColumns = useAtomValue(tableColumnsAtom);
  const visibleColumns = useAtomValue(unwrappedVisibleTableColumnsAtom);

  return (
    <GameAggregatesTable
      data={data}
      visibleColumns={visibleColumns}
      availableColumns={tableColumns}
      isLoading={!hasDefaultSeason}
      isVideoColumnVisible={isVideoColumnVisible}
    />
  );
};

export const PlayerGameAggsTable = () => (
  <ErrorBoundary fallback={<GeneralErrorBoundaryFallback />}>
    <Suspense fallback={<GameAggregatesTable isLoading visibleColumns={[...PLAYER_GAMES_DEFAULT_COLUMNS]} />}>
      <PlayerGameAggsTableWithData />
    </Suspense>
  </ErrorBoundary>
);
