import { atomWithSuspenseQuery } from 'jotai-tanstack-query';
import { Cycle } from '@statsbomb/parachute-types';
import { fetchClientAtom } from '@/atoms/queries/client';
import { atom } from 'jotai';
import { unwrap } from 'jotai/utils';
import { seasonIdsToFilterByAtom } from '../filters/highLevel/seasons';
import { competitionIdsToFilterByAtom } from '../filters/highLevel/competitions';

export const rawCyclesAtom = atomWithSuspenseQuery(get => ({
  queryKey: ['cycles'],
  queryFn: async () => {
    const { fetch } = get(fetchClientAtom);
    return (await fetch(`/cycles`)) as Promise<Cycle[]>;
  },
}));

export const selectedCycleAtom = atom(async get => {
  const cycles = (await get(rawCyclesAtom)).data;
  const competitionIdsToFilterBy = get(competitionIdsToFilterByAtom);
  const seasonIdsToFilterBy = get(seasonIdsToFilterByAtom);

  return cycles.find(
    cycle => cycle.competition_id === competitionIdsToFilterBy[0] && cycle.season_id === seasonIdsToFilterBy[0],
  );
});

export const unwrappedSelectedCycleAtom = unwrap(selectedCycleAtom, prev => prev);
