import { DATE_FORMAT } from '@/consts/date';
import { Nullable } from '@/types/generic';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export const firstDateForAge = (age: number, fixedDate: string | undefined = undefined) =>
  dayjs(fixedDate).subtract(age, 'year').format(DATE_FORMAT);

export const lastDateForAge = (age: number, fixedDate: string | undefined = undefined) =>
  dayjs(fixedDate)
    .subtract(age + 1, 'year')
    .subtract(1, 'day')
    .format(DATE_FORMAT);

export const getIsDateBeforeOrEqualToToday = (date: Nullable<string>) => {
  if (!date) return false;

  const todayInUTC = dayjs().utc();
  return dayjs(date).isBefore(todayInUTC, 'day') || dayjs(date).isSame(todayInUTC, 'day');
};
