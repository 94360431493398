import { VisDisplayModeSwitcher } from '@/components/visualisations/VisDisplayModeSwitcher';
import { useVisDisplayMode } from '@/hooks/useVisDisplayMode';
import { VisDisplayMode } from '@/types/visualisation';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { EditColumnsButton } from '../button/EditColumnsButton';
import { ExportCsvMenuItem } from '../export/ExportCsvMenuItem';
import { MoreOptionsMenu } from '../menu/MoreOptionsMenu/MoreOptionsMenu';
import { SectionControlsWrapper } from './SectionControlsWrapper';
import { VisControls } from './VisControls';

export const EventDataControls = ({
  metricKeyDropdownComponent,
  defaultDisplayMode,
  testId,
}: {
  metricKeyDropdownComponent?: ReactNode;
  defaultDisplayMode?: VisDisplayMode;
  testId?: string;
}) => {
  const { t } = useTranslation(['visualisation']);
  const [visDisplayMode] = useVisDisplayMode();

  return (
    <SectionControlsWrapper
      heading={visDisplayMode ? t(`viewing.${visDisplayMode}`) : ''}
      headerComponent={visDisplayMode === 'vis' && metricKeyDropdownComponent}
      testId={testId}
    >
      {visDisplayMode === 'table' && <EditColumnsButton />}

      <VisDisplayModeSwitcher defaultDisplayMode={defaultDisplayMode} />

      {visDisplayMode === 'table' && (
        <MoreOptionsMenu>
          <ExportCsvMenuItem />
        </MoreOptionsMenu>
      )}

      {visDisplayMode === 'vis' && <VisControls />}
    </SectionControlsWrapper>
  );
};
