import { AgeRangeFilter } from './AgeRangeFilter';
import { MinutesPlayedFilter } from './MinutesPlayedFilter';
import { HeightRangeFilter } from './HeightRangeFilter';
import { PreferredFootCheckboxGroup } from '../checkboxGroups/PreferredFootCheckboxGroup';
import { PitchPositionFilter } from './PitchPositionFilter';

export const PlayerDataLockerPlayerFilters = () => (
  <>
    <PitchPositionFilter />
    <AgeRangeFilter />
    <HeightRangeFilter />
    <MinutesPlayedFilter />
    <PreferredFootCheckboxGroup />
  </>
);
