import { Suspense, useEffect } from 'react';
import { useSetAtom } from 'jotai';
import { setAtomsToHydrateAtom } from '@/atoms/hydration';
import { DashboardWidgetVis } from '@/types/widget';
import { getDisplayAtomsToHydrate } from '@/utils/atoms/atomHydration';
import { entityVisualisationComponents } from '@/consts/widget';
import { useWidgetNavigationPath } from '@/hooks/useWidgetNavigationPath';
import { showLegendAtom } from '@/atoms/visualisation';
import { useSetGameTeamData } from '@/hooks/useSetGameTeamData';
import { metricKeyAtom } from '@/atoms/metric';
import { AtomsToHydrate } from '@/types/userConfigs';
import { DashboardWidget } from './DashboardWidget';
import { DashboardWidgetScopeProvider } from './DashboardWidgetScopeProvider';

const WidgetWrapperWithData = ({
  metric,
  entity,
  entityId,
  vis,
  widgetId,
  titleSettings,
  displaySettings,
  teamType,
  showLegend = false,
}: DashboardWidgetVis) => {
  const navigationPath = useWidgetNavigationPath(entity, entityId, vis, metric, displaySettings.radarTemplateId);
  const Component = entityVisualisationComponents[entity][vis]!;
  useSetGameTeamData(teamType);

  // Hydrate the widget
  const displayAtomsToHydrate = getDisplayAtomsToHydrate(displaySettings);
  const setAtomsToHydrate = useSetAtom(setAtomsToHydrateAtom);
  const setShowLegendAtom = useSetAtom(showLegendAtom);

  const atomsToHydrate = metric
    ? ([[metricKeyAtom, metric], ...displayAtomsToHydrate] as AtomsToHydrate)
    : displayAtomsToHydrate;

  useEffect(() => {
    setAtomsToHydrate(atomsToHydrate);
    setShowLegendAtom(showLegend);
  }, []);

  return (
    <DashboardWidget vis={vis} titleSettings={titleSettings} navigationPath={navigationPath}>
      <Component isWidget id={widgetId} />
    </DashboardWidget>
  );
};

export const DashboardWidgetWrapper = (props: DashboardWidgetVis) => (
  <Suspense>
    <DashboardWidgetScopeProvider>
      <WidgetWrapperWithData {...props} />
    </DashboardWidgetScopeProvider>
  </Suspense>
);
