import { Typography } from '@statsbomb/kitbag-components';
import { PitchViz, PositionSelectionLayer } from '@statsbomb/kitbag-datavis';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';
import { selectedPositionsAtom } from '@/atoms/filters/highLevel/positions';
import { usePositionUIElements } from '@/hooks/usePositionUIElements';
import { toggleValue } from '@/utils/array';

export const PitchPositionFilter = () => {
  const { t } = useTranslation(['filters', 'events']);
  const [selectedPositions, setSelectedPositions] = useAtom(selectedPositionsAtom);
  const positionUIElements = usePositionUIElements();

  const playerPositions = positionUIElements.map(position => ({
    ...position,
    isSelected: selectedPositions.includes(position.key),
  }));

  return (
    <div className="flex flex-col gap-y-2">
      <Typography variant="headingMedium" className="flex-grow">
        {t('position', { ns: 'filters' })}
      </Typography>
      <PitchViz
        overlay={
          <PositionSelectionLayer
            playerPositions={playerPositions}
            onPositionClick={position => {
              const newPositions = toggleValue(position, selectedPositions);
              setSelectedPositions(newPositions);
            }}
          />
        }
      />
    </div>
  );
};
