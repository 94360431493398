/* TODO (PPC-1782: DRY Season and Team selectors to reuse code across those and the `GameSelector` versions) */
import { FilterDropdown } from '@/components/dropdowns/FilterDropdown';
import { useTranslation } from 'react-i18next';
import { useAtom, useAtomValue } from 'jotai';
import { SelectorProps } from '@/types/filters';
import { Suspense } from 'react';
import {
  selectedTeamsAtom,
  teamOptionsAtom,
  selectedTeamIdsAtom,
  hasAllTeamsSelectedAtom,
} from '@/atoms/filters/highLevel/teams';
import { convertOptionsToValues } from '@/utils/array';
import { useDeepEffect } from '@/hooks/useDeepEffect';

const TeamSelectorWithAllOptionBase = ({ options = [], isLoading }: SelectorProps) => {
  const { t } = useTranslation(['filters', 'entity']);
  const selectedTeams = useAtomValue(selectedTeamsAtom);
  const [selectedTeamIds, setSelectedTeamIds] = useAtom(selectedTeamIdsAtom);
  const hasAllTeamsSelected = useAtomValue(hasAllTeamsSelectedAtom);

  const optionValues = convertOptionsToValues(options);

  const allTeamsOption = { label: t('allTeams'), value: 0 };

  useDeepEffect(() => {
    if (isLoading) return;
    /* 
      we want to select all teams if selectedTeamIds is greater than 1 then it means all teams are selected and we should persist them
      otherwise if we no longer have any selected teams, then we should select all teams.
    */
    const shouldSelectAll = selectedTeamIds.length > 1 || selectedTeams.length === 0;
    setSelectedTeamIds(shouldSelectAll ? optionValues : convertOptionsToValues(selectedTeams));
  }, [optionValues]);

  return (
    <FilterDropdown
      id="game-team-select"
      label={t('team.one', { ns: 'entity' })}
      selectedOption={hasAllTeamsSelected ? allTeamsOption : selectedTeams}
      options={[allTeamsOption, ...options]}
      onChange={options => {
        const hasSelectedAllTeamsItem = !Array.isArray(options) && options.value === allTeamsOption.value;
        setSelectedTeamIds(hasSelectedAllTeamsItem ? optionValues : convertOptionsToValues(options));
      }}
      placeholder={t('dropdownPlaceholder')}
      isLoading={isLoading}
    />
  );
};

const TeamSelectorWithAllOptionWithData = () => {
  const teamOptions = useAtomValue(teamOptionsAtom);
  return <TeamSelectorWithAllOptionBase options={teamOptions} />;
};

export const TeamSelectorWithAllOption = () => (
  <Suspense fallback={<TeamSelectorWithAllOptionBase isLoading />}>
    <TeamSelectorWithAllOptionWithData />
  </Suspense>
);
