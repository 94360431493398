import { hasAllTeamsSelectedAtom } from '@/atoms/filters/highLevel/teams';
import { gamesFilteredByTeamsAndDateAtom, selectedGameIdAtom } from '@/atoms/queries/game';
import { FilterDropdown } from '@/components/dropdowns/FilterDropdown';
import { useDeepEffect } from '@/hooks/useDeepEffect';
import { SelectorProps } from '@/types/filters';
import { convertOptionsToValues } from '@/utils/array';
import { getFormattedGameName } from '@/utils/games';
import { useAtom, useAtomValue } from 'jotai';
import { Suspense, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const GameSelectorBase = ({ options = [], isLoading }: SelectorProps) => {
  const { t } = useTranslation('entity');
  const [selectedGameId, setSelectedGameId] = useAtom(selectedGameIdAtom);
  const selectedGameOption = options.find(({ value }) => value === selectedGameId) || null;
  const hasAllTeamsSelected = useAtomValue(hasAllTeamsSelectedAtom);

  /* this handles resetting the selected game option
  to be the first game in the list when the user selects the
  "All teams" option */
  useEffect(() => {
    if (isLoading) return;

    if (options.length > 0 && hasAllTeamsSelected) {
      setSelectedGameId(options[0].value);
    }
  }, [hasAllTeamsSelected]);

  /* this handles when the game options list changes */
  useDeepEffect(() => {
    if (options.length > 0) {
      const hasSelectedOption = options.find(option => option.value === selectedGameId);
      /* if the currently selected option still exists in the list after changing teams
      persist that game being selected, else select the first option in the list */
      const newSelectedId = hasSelectedOption ? selectedGameId : options[0].value;
      setSelectedGameId(newSelectedId);
    }
  }, [options]);

  return (
    <FilterDropdown
      id="game-select"
      label={t('game.one')}
      selectedOption={selectedGameOption}
      options={options}
      onChange={options => setSelectedGameId(convertOptionsToValues(options)[0])}
      isLoading={isLoading}
    />
  );
};

const GameSelectorWithData = () => {
  const games = useAtomValue(gamesFilteredByTeamsAndDateAtom);
  const gameOptions = games.map(game => ({ value: game.game_id, label: getFormattedGameName(game) }));

  return <GameSelectorBase options={gameOptions} />;
};

export const GameSelector = () => (
  <Suspense fallback={<GameSelectorBase isLoading />}>
    <GameSelectorWithData />
  </Suspense>
);
