import { HighLevelFilterMapAtom } from '@/types/filters';
import { KeysToCamelCase, Option } from '@/types/generic';
import { convertOptionsToValues } from '@/utils/array';
import { getOptionsAtomForSection } from '@/utils/atoms/highLevelFilters';
import { atom } from 'jotai';
import { unwrap } from 'jotai/utils';
import { filterAtomDefaults } from '@/consts/defaults/filterAtomDefaults';
import { CycleWithRels } from '@statsbomb/parachute-types';
import { playerCompetitionOptionsAtom, playerCyclesOrderedAtom } from '../../queries/players';
import {
  teamCompetitionOptionsAtom,
  teamCyclesOrderedAtom,
  userDefaultTeamCyclesOrderedAtom,
} from '../../queries/teams';
import { eventDataCompetitionsOptionsAtom } from '../dataLocker/competitions';

const competitionSectionMapAtom = atom<HighLevelFilterMapAtom<Option[]>>(() => ({
  player: playerCompetitionOptionsAtom,
  team: teamCompetitionOptionsAtom,
  dataLocker: eventDataCompetitionsOptionsAtom,
  game: eventDataCompetitionsOptionsAtom,
}));

const defaultCompetitionSectionCycleMapAtom = atom<HighLevelFilterMapAtom<KeysToCamelCase<CycleWithRels>[]>>(() => ({
  player: playerCyclesOrderedAtom,
  team: teamCyclesOrderedAtom,
  dataLocker: userDefaultTeamCyclesOrderedAtom,
  game: userDefaultTeamCyclesOrderedAtom,
}));

const defaultCompetitionSectionCycle = atom(getOptionsAtomForSection(defaultCompetitionSectionCycleMapAtom));

export const competitionOptionsAtom = atom(getOptionsAtomForSection(competitionSectionMapAtom));

const unwrappedCompetitionOptionsAtom = unwrap(competitionOptionsAtom, prev => prev || []);

export const selectedCompetitionIdsAtom = atom(filterAtomDefaults.competitionIds);

const defaultCompetitionId = atom(async get => {
  const sortedCycle = await get(defaultCompetitionSectionCycle);
  const defaultCompetitionId = sortedCycle.length ? sortedCycle[0].competition.competitionId : null;
  return defaultCompetitionId;
});

const unwrappedDefaultCompetitionId = unwrap(defaultCompetitionId, prev => prev || null);

const defaultCompetitionAtom = atom(get => {
  const competitionOptions = get(unwrappedCompetitionOptionsAtom);
  const defaultCompetitionId = get(unwrappedDefaultCompetitionId);

  if (!defaultCompetitionId || !competitionOptions || competitionOptions.length === 0) return null;

  const defaultCompetitionOption = competitionOptions.find(({ value }) => defaultCompetitionId === value);

  return defaultCompetitionOption;
});

const selectedCompetitionsBaseAtom = atom(get => {
  const competitionOptions = get(unwrappedCompetitionOptionsAtom);
  const selectedCompetitionIds = get(selectedCompetitionIdsAtom);

  if (selectedCompetitionIds === null) return null;

  return competitionOptions.filter(({ value }) => selectedCompetitionIds?.includes(value));
});

export const selectedCompetitionsAtom = atom(get => {
  const defaultCompetition = get(defaultCompetitionAtom);
  const selectedCompetitions = get(selectedCompetitionsBaseAtom);

  if (selectedCompetitions) return selectedCompetitions;
  return defaultCompetition ? [defaultCompetition] : [];
});

// when querying by gender, querying by competition gender is more reliable than player gender.
// There are places where the query doesn't support filtering by competition gender but we can still achieve
// the same result by using the competitions which are already filtered by gender, as the argument for the query
export const competitionIdsToFilterByAtom = atom(get => {
  const selectedCompetitionsOptions = get(selectedCompetitionsAtom);
  // If there are no competitions selected by the user, we want to filter by all available competitions.
  // Why not just filter by nothing you might wonder? Because we only want to get back events which belong to the
  // gender that we're currently limiting to. And the way we do that is by filtering by the available competitions, since
  // only the competitions for the chosen gender will be available.
  const allCompetitionOptions = get(unwrappedCompetitionOptionsAtom);

  return convertOptionsToValues(
    selectedCompetitionsOptions.length ? selectedCompetitionsOptions : allCompetitionOptions,
  );
});
