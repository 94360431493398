import { sectionAtom } from '@/atoms/general';
import { BaseOption } from '@/types/generic';
import { convertOptionsToValues } from '@/utils/array';
import { getOptionsAtomForSection } from '@/utils/atoms/highLevelFilters';
import { atom } from 'jotai';
import { unwrap } from 'jotai/utils';
import { filterAtomDefaults } from '@/consts/defaults/filterAtomDefaults';
import { playerSeasonOptionsAtom } from '../../queries/players';
import { teamSeasonOptionsAtom } from '../../queries/teams';
import { eventDataSeasonsOptionsAtom } from '../dataLocker/seasons';

const seasonSectionMapAtom = atom(() => ({
  player: playerSeasonOptionsAtom,
  team: teamSeasonOptionsAtom,
  dataLocker: eventDataSeasonsOptionsAtom,
  game: eventDataSeasonsOptionsAtom,
}));

export const seasonOptionsAtom = atom(getOptionsAtomForSection(seasonSectionMapAtom));

export const selectedSeasonIdsAtom = atom(filterAtomDefaults.seasonIds);

export const unwrappedSeasonOptionsAtom = unwrap(seasonOptionsAtom, prev => prev || null);

export const getDefaultSeason = (
  seasonOptions: BaseOption<number>[],
  hasMultipleDefaults: boolean,
  currentYear: number,
) => {
  const filteredSeasonOptions = seasonOptions.filter(seasonOption => {
    const seasons = seasonOption.label.split('/').map(Number);
    return seasons.some(season => season <= currentYear);
  });

  if (hasMultipleDefaults) return filteredSeasonOptions.slice(0, 2);
  return filteredSeasonOptions.slice(0, 1);
};

export const defaultSeasonsAtom = atom(get => {
  const section = get(sectionAtom);
  const seasonOptions = get(unwrappedSeasonOptionsAtom);

  if (!seasonOptions || seasonOptions.length === 0) return null;
  const isDataLockerSection = section === 'dataLocker';
  const currentYear = new Date().getFullYear();
  return getDefaultSeason(seasonOptions, isDataLockerSection, currentYear);
});

const selectedSeasonsBaseAtom = atom(get => {
  const seasonOptions = get(unwrappedSeasonOptionsAtom);
  const selectedSeasonIds = get(selectedSeasonIdsAtom);

  if (selectedSeasonIds === null || !seasonOptions) return null;

  return seasonOptions.filter(({ value }) => selectedSeasonIds.includes(value));
});

export const selectedSeasonsAtom = atom(get => {
  const defaultSeasons = get(defaultSeasonsAtom);
  const selectedSeasons = get(selectedSeasonsBaseAtom);

  if (selectedSeasons) return selectedSeasons;
  return defaultSeasons || [];
});

export const seasonIdsToFilterByAtom = atom(get => {
  const selectedSeasonOptions = get(selectedSeasonsAtom);

  return convertOptionsToValues(selectedSeasonOptions);
});
