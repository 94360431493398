import { sectionAtom } from '@/atoms/general';
import { teamIdAtom, teamTypeAtom } from '@/atoms/team/team';
import { eventAtoms, radarAtoms, tableAtoms, visConfigAtoms } from '@/consts/scope';
import { useAtomValue } from 'jotai';
import { ScopeProvider } from 'jotai-scope';
import { ReactNode } from 'react';

const defaultDashboardAtoms = [...visConfigAtoms, ...radarAtoms, ...tableAtoms, ...eventAtoms];

export const DashboardWidgetScopeProvider = ({ children }: { children: ReactNode }) => {
  const section = useAtomValue(sectionAtom);
  const dashboardAtoms =
    section === 'game' ? [...defaultDashboardAtoms, teamIdAtom, teamTypeAtom] : defaultDashboardAtoms;

  return <ScopeProvider atoms={dashboardAtoms}>{children}</ScopeProvider>;
};
