import { useTranslation } from 'react-i18next';
import { LayoutWithFilters } from '@/components/layout/LayoutWithFilters';
import { PageTitle } from '@/components/head/PageTitle';
import { ScopeProvider } from 'jotai-scope';
import { tableColumnEntitiesAtom } from '@/atoms/queries/tableColumns';
import { TeamAggsTableWithPagination } from '@/components/tables/TeamAggsTable';
import { TeamAggsFilters } from '@/components/dataLocker/TeamAggsFilters';
import { TableDataControls } from '@/components/controls/TableDataControls';
import { useCsvDownload } from '@/hooks/exporting/useCsvDownload';
import { teamsNormalisedAggregatesUrl } from '@/query/url';
import { useAtomValue } from 'jotai';
import { teamAggsFilterParamsAtom } from '@/atoms/queries/team/teamAggData';
import { ScopedTableWithDefaultColumns } from '@/components/tables/ScopedTableWithDefaultColumns';
import { unwrappedTeamDataDefaultColumnsAtom } from '@/atoms/dataLocker/team/table';
import { TableId } from '@/types/table';
import { EntityAggsContainer } from '../EntityAggsContainer';

const TableWithControls = ({ pageTitle }: { pageTitle: string }) => {
  const { t } = useTranslation(['navigation']);
  const teamAggsFilterParams = useAtomValue(teamAggsFilterParamsAtom);
  useCsvDownload(pageTitle, teamsNormalisedAggregatesUrl(teamAggsFilterParams));

  return (
    <>
      <TableDataControls heading={t(`viewing.table`, { ns: 'visualisation' })} />
      <TeamAggsTableWithPagination />
    </>
  );
};

const TableWithControlsWrapper = ({ pageTitle }: { pageTitle: string }) => (
  <EntityAggsContainer>
    <ScopedTableWithDefaultColumns defaultColumnsAtom={unwrappedTeamDataDefaultColumnsAtom} tableId={TableId.TEAM_AGGS}>
      <TableWithControls pageTitle={pageTitle} />
    </ScopedTableWithDefaultColumns>
  </EntityAggsContainer>
);

export const TeamAggsPage = () => {
  const { t } = useTranslation(['navigation', 'entity']);
  const pageTitle = t('pageTitles.dataLocker', {
    entity: t('team.one', { ns: 'entity' }),
  });

  return (
    <ScopeProvider atoms={[tableColumnEntitiesAtom]}>
      <PageTitle>{pageTitle}</PageTitle>
      <LayoutWithFilters filters={<TeamAggsFilters />}>
        <TableWithControlsWrapper pageTitle={pageTitle} />
      </LayoutWithFilters>
    </ScopeProvider>
  );
};
