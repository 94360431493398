import { FilterDropdown } from '@/components/dropdowns/FilterDropdown';
import { useTranslation } from 'react-i18next';
import { useAtomValue, useSetAtom } from 'jotai';
import { SelectorProps } from '@/types/filters';
import { Suspense, useEffect } from 'react';
import { playerOptionsAtom, selectedPlayerIdsAtom, selectedPlayersAtom } from '@/atoms/filters/highLevel/players';
import { convertOptionsToValues } from '@/utils/array';

const PlayerSelectorBase = ({ options, isLoading }: SelectorProps) => {
  const { t } = useTranslation(['filters', 'entity']);
  const selectedPlayers = useAtomValue(selectedPlayersAtom);
  const setSelectedPlayerIds = useSetAtom(selectedPlayerIdsAtom);

  const optionValues = options?.map(({ value }) => value);
  useEffect(() => {
    if (!isLoading) {
      const newSelectedPlayerIds = selectedPlayers
        .map(({ value }) => value)
        .filter(playerId => optionValues?.includes(playerId));
      setSelectedPlayerIds(newSelectedPlayerIds);
    }
  }, [JSON.stringify(optionValues)]);

  return (
    <FilterDropdown
      id="player-multi-select"
      label={t('player.one', { ns: 'entity' })}
      selectedOption={selectedPlayers}
      options={options}
      onChange={options => setSelectedPlayerIds(convertOptionsToValues(options))}
      placeholder={t('dropdownPlaceholder')}
      isLoading={isLoading}
      isMulti
    />
  );
};

const PlayerSelectorWithData = () => {
  const playerOptions = useAtomValue(playerOptionsAtom);
  return <PlayerSelectorBase options={playerOptions} />;
};

export const PlayerSelector = () => (
  <Suspense fallback={<PlayerSelectorBase isLoading />}>
    <PlayerSelectorWithData />
  </Suspense>
);
