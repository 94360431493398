export const eventTypeOutcomes = [
  {
    type: '50_50',
    outcomes: ['lost', 'success_to_opposition', 'success_to_team', 'won'],
  },
  {
    type: 'bad_behaviour',
    outcomes: ['no_outcome'],
  },
  {
    type: 'ball_receipt',
    outcomes: ['complete', 'incomplete'],
  },
  {
    type: 'ball_recovery',
    outcomes: ['no_outcome'],
  },
  {
    type: 'block',
    outcomes: ['no_outcome', 'save_block'],
  },
  {
    type: 'carry',
    outcomes: ['complete', 'incomplete'],
  },
  {
    type: 'clearance',
    outcomes: ['no_outcome'],
  },
  {
    type: 'dispossessed',
    outcomes: ['no_outcome'],
  },
  {
    type: 'dribble',
    outcomes: ['complete', 'incomplete'],
  },
  {
    type: 'dribbled_past',
    outcomes: ['no_outcome'],
  },
  {
    type: 'duel',
    outcomes: ['lost_in_play', 'lost_out', 'no_outcome', 'success_in_play', 'success_out', 'won'],
  },
  {
    type: 'error',
    outcomes: ['no_outcome'],
  },
  {
    type: 'foul_committed',
    outcomes: ['no_outcome'],
  },
  {
    type: 'foul_won',
    outcomes: ['no_outcome'],
  },
  {
    type: 'goalkeeper',
    outcomes: [
      'claim',
      'clear',
      'collected_twice',
      'fail',
      'in_play_danger',
      'in_play_safe',
      'lost',
      'lost_in_play',
      'lost_out',
      'no_outcome',
      'no_touch',
      'punched_out',
      'saved_twice',
      'success',
      'success_in_play',
      'success_out',
      'touched_in',
      'touched_out',
      'won',
    ],
  },
  {
    type: 'half_end',
    outcomes: ['no_outcome'],
  },
  {
    type: 'half_start',
    outcomes: ['no_outcome'],
  },
  {
    type: 'injury_stoppage',
    outcomes: ['no_outcome'],
  },
  {
    type: 'interception',
    outcomes: ['lost_in_play', 'lost_out', 'success_in_play', 'success_out', 'won'],
  },
  {
    type: 'miscontrol',
    outcomes: ['no_outcome'],
  },
  {
    type: 'offside',
    outcomes: ['no_outcome'],
  },
  {
    type: 'own_goal_against',
    outcomes: ['no_outcome'],
  },
  {
    type: 'own_goal_for',
    outcomes: ['no_outcome'],
  },
  {
    type: 'pass',
    outcomes: ['complete', 'incomplete', 'injury_clearance', 'out', 'pass_offside', 'unknown'],
  },
  {
    type: 'player_off',
    outcomes: ['no_outcome'],
  },
  {
    type: 'player_on',
    outcomes: ['no_outcome'],
  },
  {
    type: 'pressure',
    outcomes: ['no_outcome'],
  },
  {
    type: 'referee_ball_drop',
    outcomes: ['no_outcome'],
  },
  {
    type: 'shield',
    outcomes: ['no_outcome'],
  },
  {
    type: 'shot',
    outcomes: ['blocked', 'goal', 'off_target', 'post', 'saved', 'saved_off_t', 'saved_to_post', 'wayward'],
  },
  {
    type: 'starting_xi',
    outcomes: ['no_outcome'],
  },
  {
    type: 'substitution',
    outcomes: ['injury', 'no_outcome', 'tactical'],
  },
  {
    type: 'tactical_shift',
    outcomes: ['no_outcome'],
  },
];
