import { unwrappedSelectedGameAtom } from '@/atoms/queries/game';
import { teamIdAtom, teamTypeAtom } from '@/atoms/team/team';
import { TeamType } from '@/types/filters';
import { Nullable } from '@/types/generic';
import { useAtomValue, useSetAtom } from 'jotai';
import { useEffect } from 'react';

export const useSetGameTeamData = (teamType?: TeamType) => {
  const setTeamId = useSetAtom(teamIdAtom);
  const setTeamType = useSetAtom(teamTypeAtom);
  const selectedGame = useAtomValue(unwrappedSelectedGameAtom);
  let gameTeamId: Nullable<number> = null;

  if (teamType && selectedGame) {
    gameTeamId = teamType === TeamType.HOME ? selectedGame.home_team_id : selectedGame.away_team_id;
  }

  useEffect(() => {
    if (gameTeamId) setTeamId(gameTeamId);
    if (teamType) setTeamType(teamType);
  }, [gameTeamId]);
};
