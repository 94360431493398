import { PlayerDataLockerTabValue } from '@/types/tabs';
import { ConfigFilterAtomValues } from '@/types/userConfigs';

export const playerDataLockerTabFiltersToClear: Record<PlayerDataLockerTabValue, (keyof ConfigFilterAtomValues)[]> = {
  general: ['competitionIds', 'seasonIds', 'teamIds', 'playerIds', 'startDate', 'endDate'],
  player: ['positions', 'minAge', 'maxAge', 'minHeight', 'maxHeight', 'minMinutes', 'maxMinutes', 'preferredFoot'],
  metrics: [],
};

export const playerDataLockerFiltersToClear = Object.values(playerDataLockerTabFiltersToClear).flat();
