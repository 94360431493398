import { FullScreenCard } from '@/components/card/FullScreenCard';
import { PageTitle } from '@/components/head/PageTitle';
import { AppShellContents } from '@/components/layout/AppShell';
import { VideoProvider } from '@/components/Providers';
import { TopBar } from '@/components/topBar/TopBar';
import { TopBarHeading } from '@/components/topBar/TopBarHeading';
import { Card, CardBody } from '@statsbomb/kitbag-components';
import { MyPlaylists } from '@statsbomb/video-client-js';
import { useTranslation } from 'react-i18next';

export const VideoPlaylists = () => {
  const { t } = useTranslation(['navigation', 'preferences']);

  return (
    <>
      <PageTitle>{t('primary.videoPlaylists')} </PageTitle>
      <AppShellContents
        topBar={
          <TopBar>
            <TopBarHeading>{t('primary.videoPlaylists')}</TopBarHeading>
          </TopBar>
        }
      >
        <FullScreenCard>
          <Card>
            <CardBody>
              <VideoProvider>
                <MyPlaylists />
              </VideoProvider>
            </CardBody>
          </Card>
        </FullScreenCard>
      </AppShellContents>
    </>
  );
};
