import { Nullable } from '@/types/generic';
import { SORT_DIRECTION } from '@/types/ordering';
import { TableColumnsEditorTabs, TableId } from '@/types/table';
import { unwrap } from 'jotai/utils';
import { atom } from 'jotai';
import { customTableColumnsAtom } from './queries/userConfigs';

/**
 * Sorting in the application is handled both server side and client side under different circumstances.
 * In order to prevent API calls from triggering when we just want to sort client side, we have separate
 * atoms for server side sorting and client side sorting.
 *
 * When the conditions are met for client side sorting, we just update the clientOrder atoms. This
 * will allow the code which needs to perform the client sorting to do so without triggering an API call.
 *
 * If we need to sort server side, we should call the setter on orderByAtom and orderDirectionAtom. This will
 * BOTH the server side and client side atoms. The getter of these atoms will return the client side values.
 *
 * Doing this means that places which need to show the currently applied sort order will work regardless of whether
 * we're doing server side or client side sorting.
 */
export const serverOrderByAtom = atom<Nullable<string>>(null);
export const serverOrderDirectionAtom = atom<SORT_DIRECTION>(SORT_DIRECTION.DESC);

export const clientOrderByAtom = atom<Nullable<string>>(null);
export const clientOrderDirectionAtom = atom<SORT_DIRECTION>(SORT_DIRECTION.DESC);

export const orderByAtom = atom(
  get => get(clientOrderByAtom),
  (_, set, newValue: string) => {
    set(serverOrderByAtom, newValue);
    set(clientOrderByAtom, newValue);
  },
);

export const orderDirectionAtom = atom(
  get => get(clientOrderDirectionAtom),
  (_, set, newValue: SORT_DIRECTION) => {
    set(serverOrderDirectionAtom, newValue);
    set(clientOrderDirectionAtom, newValue);
  },
);

export const defaultTableColumnsAtom = atom<string[]>([]);
export const visibleTableColumnsAtom = atom<string[]>([]);

export const unwrappedVisibleTableColumnsAtom = unwrap(visibleTableColumnsAtom, prev => prev || []);

export const tableColumnsEditorActiveTabAtom = atom(TableColumnsEditorTabs.COLUMN_SELECT);

export const tableIdAtom = atom<Nullable<TableId>>(null);

const defaultTableConfig = { configId: '', selectedColumns: [] };

export const tableConfigAtom = atom(async get => {
  const tableId = get(tableIdAtom);
  const customTableColumns = await get(customTableColumnsAtom);
  const tableConfig = customTableColumns.find(tableConfig => tableConfig.definition.tableId === tableId);

  if (!tableConfig) {
    return defaultTableConfig;
  }

  return {
    configId: tableConfig.configId,
    selectedColumns: tableConfig.definition.selectedColumns,
  };
});

export const unwrappedTableConfigAtom = unwrap(tableConfigAtom, prev => prev || defaultTableConfig);
