import { ReactNode, Suspense } from 'react';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from '@sentry/react';
import { StatusTranslationKey } from '@/types/status';
import { unwrappedSelectedGameNameAtom } from '@/atoms/queries/game';
import { useEntityPageTitle } from '@/hooks/useEntityPageTitle';
import { ContentState } from '@/components/contentState/ContentState';
import { PageTitle } from '@/components/head/PageTitle';
import { DashboardContent } from '@/components/layout/DashboardContent';
import { GameLayoutWithFilters } from '@/components/layout/game/GameLayoutWithFilters';
import { gameIdAtom } from '@/atoms/game/game';
import { gameDashboardWidgetsAtom } from '@/atoms/queries/userConfigs';

export const GameDashboardContentFallback = ({ children }: { children: ReactNode }) => (
  <ErrorBoundary fallback={<ContentState status={StatusTranslationKey.ERROR} />}>
    <Suspense fallback={<ContentState status={StatusTranslationKey.LOADING} />}>{children}</Suspense>
  </ErrorBoundary>
);

const GameDashboardWithData = () => {
  const gameId = useAtomValue(gameIdAtom);
  const gameDashboardWidgets = useAtomValue(gameDashboardWidgetsAtom);

  return <DashboardContent entityId={gameId} dashboardWidgets={gameDashboardWidgets} />;
};

export const GameDashboardPage = () => {
  const { t } = useTranslation('navigation');
  const selectedGameName = useAtomValue(unwrappedSelectedGameNameAtom);
  const page = t('secondary.dashboard');
  const pageTitle = useEntityPageTitle(page, selectedGameName);

  return (
    <>
      <PageTitle>{pageTitle}</PageTitle>
      <GameLayoutWithFilters>
        <GameDashboardContentFallback>
          <GameDashboardWithData />
        </GameDashboardContentFallback>
      </GameLayoutWithFilters>
    </>
  );
};
