import { CardHeader } from '@statsbomb/kitbag-components';
import { teamMetricDataAtom } from '@/atoms/team/metric';
import { ExpandVideoButton } from '@/components/button/ExpandVideoButton';
import { PreviewItemsLayout } from '@/components/layout/PreviewItemsLayout';
import { MetricKeyDropdown } from '@/components/metric/MetricKeyDropdown';
import { TeamVisEventsTable } from '@/components/team/vis/TeamVisEventsTable';
import { VisualisationComponent } from '@/components/visualisations/PitchVisualisation/VisualisationComponent';
import { teamPitchVisComponents } from '@/consts/visualisationComponents';
import { ScopedTableWithDefaultColumns } from '@/components/tables/ScopedTableWithDefaultColumns';
import { unwrappedEventsDataDefaultColumnsAtom } from '@/atoms/dataLocker/events/table';
import { TableId } from '@/types/table';

export const TeamVideoPreview = () => (
  <>
    <CardHeader>
      <MetricKeyDropdown metricDataAtom={teamMetricDataAtom} />
      <ExpandVideoButton />
    </CardHeader>
    <PreviewItemsLayout>
      <VisualisationComponent allowInteraction visualisations={teamPitchVisComponents} />
      <ScopedTableWithDefaultColumns
        defaultColumnsAtom={unwrappedEventsDataDefaultColumnsAtom}
        tableId={TableId.TEAM_EVENTS}
      >
        <TeamVisEventsTable />
      </ScopedTableWithDefaultColumns>
    </PreviewItemsLayout>
  </>
);
