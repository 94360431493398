import { EditColumnsButton } from '@/components/button/EditColumnsButton';
import { ExportCsvMenuItem } from '../export/ExportCsvMenuItem';
import { MoreOptionsMenu } from '../menu/MoreOptionsMenu/MoreOptionsMenu';
import { SectionControlsWrapper } from './SectionControlsWrapper';

export const TableDataControls = ({ heading }: { heading: string }) => (
  <SectionControlsWrapper heading={heading}>
    <EditColumnsButton />
    <MoreOptionsMenu>
      <ExportCsvMenuItem />
    </MoreOptionsMenu>
  </SectionControlsWrapper>
);
