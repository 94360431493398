import { atom } from 'jotai';
import { Nullable } from '@/types/generic';
import { TeamType } from '@/types/filters';
import { unwrap } from 'jotai/utils';
import { teamTypeAtom } from '../team/team';
import { selectedGameNameAtom } from '../queries/game';

export const gameIdAtom = atom<Nullable<number>>(null);

export const gameTeamNameAtom = atom(async get => {
  const selectedGameName = await get(selectedGameNameAtom);
  const teamType = get(teamTypeAtom);

  const [homeTeamName, awayTeamName] = selectedGameName.split(' vs. ');
  return teamType === TeamType.HOME ? homeTeamName : awayTeamName;
});

export const unwrappedGameTeamNameAtom = unwrap(gameTeamNameAtom, prev => prev || '');
