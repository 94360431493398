import { useAtomValue } from 'jotai';
import { ScopeProvider } from 'jotai-scope';
import { Outlet } from 'react-router-dom';
import { layoutScopeAtoms } from '@/consts/scope';
import { gameIdAtom } from '@/atoms/game/game';
import { useSetGameId } from '@/hooks/useSetGameId';
import { ContentState } from '@/components/contentState/ContentState';
import { StatusTranslationKey } from '@/types/status';
import { EntityTopBar } from '@/components/topBar/EntityTopBar';
import { gameTitleAtom } from '@/atoms/queries/game';
import { AppShellContents } from '../AppShell';
import { SectionWrapper } from '../SectionWrapper';

const GameLayoutInner = () => (
  <SectionWrapper section="game">
    <Outlet />
  </SectionWrapper>
);

const GameLayoutWithData = () => {
  useSetGameId();
  const gameId = useAtomValue(gameIdAtom);

  return (
    <AppShellContents topBar={<EntityTopBar selectedEntityNameAtom={gameTitleAtom} />}>
      {gameId ? <GameLayoutInner /> : <ContentState status={StatusTranslationKey.LOADING} />}
    </AppShellContents>
  );
};

export const GameLayout = () => (
  <ScopeProvider atoms={layoutScopeAtoms}>
    <GameLayoutWithData />
  </ScopeProvider>
);
