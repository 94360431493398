import { convertFilterParamsToString } from '@/utils/api';
import { convertMarkerDataToShotMapMarkers } from '@/utils/atoms/eventData';
import { EventMarker } from '@statsbomb/parachute-types';
import { atom } from 'jotai';
import { atomWithSuspenseQuery } from 'jotai-tanstack-query';
import { unwrap } from 'jotai/utils';
import { ALL_ITEMS } from '@/consts/interface';
import { gameIdAtom } from '@/atoms/game/game';
import { teamIdAtom } from '@/atoms/team/team';
import { fetchClientAtom } from '../client';

const rawGameTeamShotMarkersAtom = atomWithSuspenseQuery(get => {
  const queryKey = ['gameTeamShotMarkers', get(gameIdAtom), get(teamIdAtom)] as const;

  const queryFn = async ({ queryKey: [, gameId, teamId] }: { queryKey: typeof queryKey }) => {
    if (!gameId || !teamId) return [];
    const { fetch } = get(fetchClientAtom);
    return (await fetch(
      `/event-markers${convertFilterParamsToString({
        limit: ALL_ITEMS,
      })}&eq[event.team_id]=${teamId}&eq[event.game_id]=${gameId}&in[event.type]=shot␞own_goal_for&neq[event.period]=5`,
    )) as Promise<EventMarker[]>;
  };

  return { queryKey, queryFn };
});

export const gameTeamShotMarkersAtom = atom(async get => {
  const gameTeamShotMarkers = await get(rawGameTeamShotMarkersAtom);
  return convertMarkerDataToShotMapMarkers(gameTeamShotMarkers.data);
});

export const unwrappedGameTeamShotMarkersAtom = unwrap(gameTeamShotMarkersAtom, prev => prev || []);
