import { seasonOptionsAtom, selectedSeasonIdsAtom, selectedSeasonsAtom } from '@/atoms/filters/highLevel/seasons';
import { FilterDropdown } from '@/components/dropdowns/FilterDropdown';
import { SelectorProps } from '@/types/filters';
import { convertOptionsToValues } from '@/utils/array';
import { useAtom, useAtomValue } from 'jotai';
import { Suspense, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const SeasonSelectorBase = ({ options, isLoading }: SelectorProps) => {
  const { t } = useTranslation(['filters', 'entity']);
  const selectedSeasons = useAtomValue(selectedSeasonsAtom);
  const [selectedSeasonIds, setSelectedSeasonIds] = useAtom(selectedSeasonIdsAtom);
  const optionValues = options?.map(({ value }) => value);

  useEffect(() => {
    if (!isLoading && selectedSeasonIds) {
      const newSelectedSeasonIds = selectedSeasonIds.filter(seasonId => optionValues?.includes(seasonId));
      setSelectedSeasonIds(newSelectedSeasonIds);
    }
  }, [JSON.stringify(optionValues)]);

  return (
    <FilterDropdown
      id="season-multi-select"
      label={t('season.one', { ns: 'entity' })}
      selectedOption={selectedSeasons}
      options={options}
      onChange={options => setSelectedSeasonIds(convertOptionsToValues(options))}
      placeholder={t('dropdownPlaceholder')}
      isLoading={isLoading}
      isMulti
    />
  );
};

const SeasonSelectorWithData = () => {
  const seasonOptions = useAtomValue(seasonOptionsAtom);
  return <SeasonSelectorBase options={seasonOptions} />;
};

export const SeasonSelector = () => (
  <Suspense fallback={<SeasonSelectorBase isLoading />}>
    <SeasonSelectorWithData />
  </Suspense>
);
