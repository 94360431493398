import { navigationOpenAtom } from '@/atoms/navigation';
import { NavigationItem, useNavigationItems } from '@/hooks/useNavigationItems';
import { ErrorBoundary } from '@sentry/react';
import { useKitbagAuth } from '@statsbomb/kitbag-auth';
import { SideNavigationMenuList, SideNavigationItem } from '@statsbomb/kitbag-components';
import { useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

// TODO (KC-189: Temporary links component to display until the side nav footer KC component supports flyout secondary nav items)
export const SideNavFooterLinksBase = ({
  isTouch,
  navigationItems,
}: {
  isTouch: boolean;
  navigationItems: NavigationItem[];
}) => {
  const { pathname } = useLocation();
  const { logout } = useKitbagAuth();
  const {
    t,
    i18n: { language },
  } = useTranslation('general');
  const setIsNavOpen = useSetAtom(navigationOpenAtom);

  const isRootPath = pathname === '/' || pathname === `/${language}`;

  return (
    <div className="[&_ul]:pb-0">
      <SideNavigationMenuList>
        {navigationItems.map(({ icon, path, label }) => (
          <SideNavigationItem
            key={path}
            version={2}
            icon={icon}
            component={Link}
            componentProps={{ to: path, onClick: () => isTouch && setIsNavOpen(false) }}
            /* if path is not just blank or just the selected language */
            isSelected={!isRootPath && path.includes(pathname)}
          >
            {label}
          </SideNavigationItem>
        ))}
        <SideNavigationItem
          version={2}
          icon="LogOut"
          component="button"
          componentProps={{
            'aria-label': t('logout'),
            onClick: () => {
              logout({
                logoutParams: {
                  returnTo: window.location.origin,
                },
              });
            },
          }}
        >
          {t('logout')}
        </SideNavigationItem>
      </SideNavigationMenuList>
    </div>
  );
};

export const EmptySideNavFooterLinks = ({ isTouch }: { isTouch: boolean }) => (
  <SideNavFooterLinksBase navigationItems={[]} isTouch={isTouch} />
);

const SideNavFooterLinksWithData = ({ isTouch }: { isTouch: boolean }) => {
  const { footer } = useNavigationItems();
  return <SideNavFooterLinksBase isTouch={isTouch} navigationItems={footer} />;
};

export const SideNavFooterLinks = ({ isTouch }: { isTouch: boolean }) => (
  <ErrorBoundary fallback={<EmptySideNavFooterLinks isTouch={isTouch} />}>
    <SideNavFooterLinksWithData isTouch={isTouch} />
  </ErrorBoundary>
);
