import { useSetDefaultAndVisibleTableColumns } from '@/hooks/useSetTableDefaultAndVisibleColumns';
import { ReactNode } from 'react';
import { Atom } from 'jotai';
import { Nullable } from '@/types/generic';
import { TableId } from '@/types/table';
import { tableIdAtom } from '@/atoms/table';
import { useHydrateAtoms } from 'jotai/utils';
import { TableScopeProvider } from './TableScopeProvider';

const ScopedTableWithDefaultColumnsInner = ({
  defaultColumnsAtom,
  tableId = null,
  children,
}: {
  defaultColumnsAtom: Atom<string[]>;
  tableId?: Nullable<TableId>;
  children: ReactNode;
}) => {
  useSetDefaultAndVisibleTableColumns(defaultColumnsAtom);
  useHydrateAtoms([[tableIdAtom, tableId]]);
  return children;
};

export const ScopedTableWithDefaultColumns = ({
  defaultColumnsAtom,
  tableId,
  children,
}: {
  defaultColumnsAtom: Atom<string[]>;
  tableId?: Nullable<TableId>;
  children: ReactNode;
}) => (
  <TableScopeProvider>
    <ScopedTableWithDefaultColumnsInner defaultColumnsAtom={defaultColumnsAtom} tableId={tableId}>
      {children}
    </ScopedTableWithDefaultColumnsInner>
  </TableScopeProvider>
);
