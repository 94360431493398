import { teamGamesBySeasonAtom, teamSelectedGamesAtom } from '@/atoms/filters/team/teamGames';
import { Accordion } from '@/components/accordion/Accordion';
import { ResetAllFiltersButton } from '@/components/button/ResetAllFiltersButton';
import { CompetitionSelector } from '@/components/filters/CompetitionSelector';
import { GameRangeFilterGroup } from '@/components/filters/GameRangeFilterGroup';
import { GameSelector } from '@/components/filters/GameSelector';
import { GameTypeFilter } from '@/components/filters/GameTypeFilter';
import { SeasonSelector } from '@/components/filters/SeasonSelector';
import { UserFilters } from '@/components/filters/UserFilters/UserFilters';
import { useSetTeamDataDebouncedFilterParams } from '@/hooks/apiFilterParams/useSetTeamDataDebounceFilterParams';
import { useTranslation } from 'react-i18next';

export const TeamHighLevelFilters = () => {
  useSetTeamDataDebouncedFilterParams();
  const { t } = useTranslation('filters');

  return (
    <div className="flex flex-col">
      <UserFilters />
      <Accordion title={t('general')} isDefaultExpanded>
        <ResetAllFiltersButton />
        <CompetitionSelector isMulti />
        <SeasonSelector />
      </Accordion>
      <Accordion title={t('games')} isDefaultExpanded>
        <GameRangeFilterGroup />
        <GameTypeFilter />
        <GameSelector gamesGroupedBySeasonAtom={teamGamesBySeasonAtom} selectedGamesAtom={teamSelectedGamesAtom} />
      </Accordion>
    </div>
  );
};
