import { unwrappedTeamGameDataDefaultColumnsAtom } from '@/atoms/dataLocker/team/game/table';
import { tableColumnEntitiesAtom } from '@/atoms/queries/tableColumns';
import { teamGameAggsCountAtom } from '@/atoms/queries/team/teamGameAggData';
import { unwrappedSelectedTeamNameAtom } from '@/atoms/team/info';
import { TableDataControls } from '@/components/controls/TableDataControls';
import { PageTitle } from '@/components/head/PageTitle';
import { LayoutWithFilters } from '@/components/layout/LayoutWithFilters';
import { TablePagination } from '@/components/tables/TablePagination';
import { TeamHighLevelFilters } from '@/components/team/filters/TeamHighLevelFilters';
import { TeamGameAggsTable } from '@/components/team/tables/TeamGameAggsTable';
import { useEntityPageTitle } from '@/hooks/useEntityPageTitle';
import { useTableColumns } from '@/hooks/useTableColumns';
import { Card } from '@statsbomb/kitbag-components';
import { useAtomValue } from 'jotai';
import { ScopeProvider } from 'jotai-scope';
import { useTranslation } from 'react-i18next';
import { useCsvDownload } from '@/hooks/exporting/useCsvDownload';
import { teamGamesTotalAggregatesUrl } from '@/query/url';
import { teamIdAtom } from '@/atoms/team/team';
import { teamGameStatsFilterParamsAtom } from '@/atoms/queries/team/teamAggData';
import { ScopedTableWithDefaultColumns } from '@/components/tables/ScopedTableWithDefaultColumns';
import { TableId } from '@/types/table';

const TableWithControls = ({ pageTitle }: { pageTitle: string }) => {
  const { t } = useTranslation('games');
  useTableColumns(['competition', 'game', 'team_total_metric']);
  const teamId = useAtomValue(teamIdAtom);
  const teamGameStatsFilterParams = useAtomValue(teamGameStatsFilterParamsAtom);
  useCsvDownload(pageTitle, teamGamesTotalAggregatesUrl(teamId, teamGameStatsFilterParams));

  return (
    <>
      <TableDataControls heading={t('latestPerformances')} />
      <TeamGameAggsTable />
    </>
  );
};

export const TeamGamesPage = () => {
  const { t } = useTranslation('navigation');
  const teamName = useAtomValue(unwrappedSelectedTeamNameAtom);
  const pageTitle = useEntityPageTitle(t('secondary.games'), teamName);

  return (
    <ScopeProvider atoms={[tableColumnEntitiesAtom]}>
      <PageTitle>{pageTitle}</PageTitle>
      <LayoutWithFilters filters={<TeamHighLevelFilters />}>
        <Card>
          <ScopedTableWithDefaultColumns
            defaultColumnsAtom={unwrappedTeamGameDataDefaultColumnsAtom}
            tableId={TableId.TEAM_GAMES}
          >
            <TableWithControls pageTitle={pageTitle} />
            <TablePagination dataLengthAtom={teamGameAggsCountAtom} />
          </ScopedTableWithDefaultColumns>
        </Card>
      </LayoutWithFilters>
    </ScopeProvider>
  );
};
