import { defaultTableColumnsAtom, unwrappedTableConfigAtom, visibleTableColumnsAtom } from '@/atoms/table';
import { Atom, useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useDeepEffect } from './useDeepEffect';

export const useSetDefaultAndVisibleTableColumns = (defaultTableColumnsDefinitionAtom: Atom<string[]>) => {
  const defaultTableColumnDefinitions = useAtomValue(defaultTableColumnsDefinitionAtom);
  const { selectedColumns: userSavedColumns } = useAtomValue(unwrappedTableConfigAtom);
  const [defaultTableColumns, setDefaultTableColumns] = useAtom(defaultTableColumnsAtom);
  const setVisibleTableColumns = useSetAtom(visibleTableColumnsAtom);

  useDeepEffect(() => {
    setDefaultTableColumns(defaultTableColumnDefinitions);
    const newVisibleColumns = userSavedColumns.length ? userSavedColumns : defaultTableColumns;
    setVisibleTableColumns(newVisibleColumns);
  }, [defaultTableColumnDefinitions, defaultTableColumns]);
};
