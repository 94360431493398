import {
  playerGameDataTableIdAtom,
  unwrappedPlayerGameDataDefaultColumnsAtom,
} from '@/atoms/dataLocker/player/game/table';
import { unwrappedSelectedPlayerNameAtom } from '@/atoms/player/info';
import { playerGameAggsCountAtom } from '@/atoms/queries/player/playerGameAggData';
import { tableColumnEntitiesAtom } from '@/atoms/queries/tableColumns';
import { TableDataControls } from '@/components/controls/TableDataControls';
import { PageTitle } from '@/components/head/PageTitle';
import { LayoutWithFilters } from '@/components/layout/LayoutWithFilters';
import { PlayerGamesFilters } from '@/components/player/filters/PlayerGamesFilters';
import { PlayerGameAggsTable } from '@/components/player/tables/PlayerGameAggsTable';
import { TablePagination } from '@/components/tables/TablePagination';
import { useEntityPageTitle } from '@/hooks/useEntityPageTitle';
import { useTableColumns } from '@/hooks/useTableColumns';
import { useAtomValue } from 'jotai';
import { ScopeProvider } from 'jotai-scope';
import { useTranslation } from 'react-i18next';
import { useCsvDownload } from '@/hooks/exporting/useCsvDownload';
import { playerGamesTotalAggregatesUrl } from '@/query/url';
import { playerIdAtom } from '@/atoms/player/player';
import { playerGameStatsFilterParamsAtom } from '@/atoms/queries/player/playerAggData';
import { Card } from '@statsbomb/kitbag-components';
import { ScopedTableWithDefaultColumns } from '@/components/tables/ScopedTableWithDefaultColumns';
import { Suspense } from 'react';

const TableWithControls = ({ pageTitle }: { pageTitle: string }) => {
  const { t } = useTranslation('games');
  useTableColumns(['competition', 'game', 'player_total_metric']);
  const playerId = useAtomValue(playerIdAtom);
  const playerGameStatsFilterParams = useAtomValue(playerGameStatsFilterParamsAtom);
  useCsvDownload(pageTitle, playerGamesTotalAggregatesUrl(playerId, playerGameStatsFilterParams));

  return (
    <>
      <TableDataControls heading={t('latestPerformances')} />
      <PlayerGameAggsTable />
    </>
  );
};

const PlayerGamesContent = ({ pageTitle }: { pageTitle: string }) => {
  const tableId = useAtomValue(playerGameDataTableIdAtom);
  return (
    <ScopedTableWithDefaultColumns defaultColumnsAtom={unwrappedPlayerGameDataDefaultColumnsAtom} tableId={tableId}>
      <TableWithControls pageTitle={pageTitle} />
      <TablePagination dataLengthAtom={playerGameAggsCountAtom} />
    </ScopedTableWithDefaultColumns>
  );
};

export const PlayerGamesPage = () => {
  useTableColumns(['competition', 'game', 'player_total_metric']);
  const { t } = useTranslation('navigation');
  const playerName = useAtomValue(unwrappedSelectedPlayerNameAtom);
  const pageTitle = useEntityPageTitle(t('secondary.games'), playerName);

  return (
    <ScopeProvider atoms={[tableColumnEntitiesAtom]}>
      <PageTitle>{pageTitle}</PageTitle>
      <LayoutWithFilters filters={<PlayerGamesFilters />}>
        <Card>
          <Suspense>
            <PlayerGamesContent pageTitle={pageTitle} />
          </Suspense>
        </Card>
      </LayoutWithFilters>
    </ScopeProvider>
  );
};
