import { Option, Section, StringOption } from './generic';
import { EntityGameWithCompetitionName } from './game';
import { Translation } from './translations';
import { PromiseAtom } from './atom';

export enum GameType {
  HOME = 'HOME',
  AWAY = 'AWAY',
  HOME_AWAY = 'HOME_AWAY',
}

export enum TeamType {
  HOME = GameType.HOME,
  AWAY = GameType.AWAY,
}

export interface PositionOption extends StringOption {
  other?: boolean;
}

export interface SelectorProps<T = Option> {
  options?: T[];
  isLoading?: boolean;
  isMulti?: boolean;
}

export interface GamesBySeason {
  seasonId: number;
  seasonName: string;
  games: EntityGameWithCompetitionName[];
}

export interface GameRangeFilterOption {
  key: 'dateRange' | 'period';
  translation: Translation;
}

export type HighLevelFilterMapAtom<T> = Partial<Record<Section, PromiseAtom<T>>>;
