import { playerTeamsOptionsAtom } from '@/atoms/queries/players';
import { teamsOptionsAtom } from '@/atoms/queries/teams';
import { filterAtomDefaults } from '@/consts/defaults/filterAtomDefaults';
import { HighLevelFilterMapAtom } from '@/types/filters';
import { Option } from '@/types/generic';
import { getOptionsAtomForSection } from '@/utils/atoms/highLevelFilters';
import { atom } from 'jotai';
import { unwrap } from 'jotai/utils';

const teamSectionMapAtom = atom<HighLevelFilterMapAtom<Option[]>>(() => ({
  player: playerTeamsOptionsAtom,
  team: teamsOptionsAtom,
  dataLocker: teamsOptionsAtom,
  game: teamsOptionsAtom,
}));

export const teamOptionsAtom = atom(getOptionsAtomForSection(teamSectionMapAtom));

export const selectedTeamIdsAtom = atom(filterAtomDefaults.teamIds);

export const unwrappedTeamOptions = unwrap(teamOptionsAtom, prev => prev || []);
export const selectedTeamsAtom = atom(get => {
  const teamOptions = get(unwrappedTeamOptions);
  const selectedTeamIds = get(selectedTeamIdsAtom);

  return teamOptions.filter(({ value }) => selectedTeamIds.includes(value));
});

export const hasAllTeamsSelectedAtom = atom(get => {
  const teamOptions = get(unwrappedTeamOptions);
  const selectedTeams = get(selectedTeamsAtom);

  return selectedTeams.length === teamOptions.length;
});

// same as selectedTeamsAtom but without unwrapping the async atom. This is necessary
// so the suspense in WidgetFiltersModal doesn't render before all the data is ready
export const selectedTeamsAsyncAtom = atom(async get => {
  const teamOptions = await get(teamOptionsAtom);
  const selectedTeamIds = get(selectedTeamIdsAtom);

  return teamOptions.filter(({ value }) => selectedTeamIds.includes(value));
});
