import { playerAggsCountAtom } from '@/atoms/queries/player/playerAggData';
import { tableColumnsAtom } from '@/atoms/queries/tableColumns';
import { teamSquadAtom } from '@/atoms/queries/team/teamSquadData';
import { GeneralErrorBoundaryFallback } from '@/components/errorBoundary/ErrorBoundaryFallback';
import { ErrorBoundary } from '@sentry/react';
import { useAtomValue } from 'jotai';
import { Suspense } from 'react';
import { unwrappedVisibleTableColumnsAtom } from '@/atoms/table';
import { AggsTable } from './AggsTable';
import { TablePagination } from './TablePagination';
import { AggsTableErrorFallback } from './AggsTableErrorFallback';

const TeamSquadTableWithData = () => {
  const teamSquad = useAtomValue(teamSquadAtom);
  const tableColumns = useAtomValue(tableColumnsAtom);
  const visibleColumns = useAtomValue(unwrappedVisibleTableColumnsAtom);

  return <AggsTable data={teamSquad} availableColumns={tableColumns} visibleColumns={visibleColumns} entity="player" />;
};

export const TeamSquadTable = () => (
  <AggsTableErrorFallback>
    <Suspense fallback={<AggsTable isLoading entity="player" />}>
      <TeamSquadTableWithData />
    </Suspense>
  </AggsTableErrorFallback>
);

export const TeamSquadTableWithPagination = () => (
  <ErrorBoundary fallback={<GeneralErrorBoundaryFallback />}>
    <TeamSquadTable />
    <TablePagination dataLengthAtom={playerAggsCountAtom} />
  </ErrorBoundary>
);
