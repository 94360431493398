import { useHydrateAtoms } from 'jotai/utils';
import { CardBody } from '@statsbomb/kitbag-components';
import { itemsPerPageAtom } from '@/atoms/pagination';
import { tableColumnEntitiesAtom } from '@/atoms/queries/tableColumns';
import { ALL_ITEMS } from '@/consts/interface';
import { MINUTES_PLAYED_COLUMN } from '@/consts/dataLocker/table';
import { CardHeightWrapper } from '@/components/card/CardHeightWrapper';
import { TeamSquadTable } from '@/components/tables/TeamSquadTable';
import { visibleTableColumnsAtom } from '@/atoms/table';

// A "lighter" version of the regular team squad page table component for displaying on the team dashboard.
export const TeamDashboardSquadTable = () => {
  useHydrateAtoms([
    [tableColumnEntitiesAtom, ['player', 'player_metric']],
    [visibleTableColumnsAtom, ['playerLink', 'player.age', MINUTES_PLAYED_COLUMN]],
    [itemsPerPageAtom, ALL_ITEMS],
  ]);

  return (
    <CardHeightWrapper>
      <CardBody>
        {/* setting the loading component to 288-40 to take into account the table header height.
        This prevents the widget from jumping in height when going from loading to not loading  */}
        <div className="[&>div]:m-0 [&>div>div:nth-child(1)]:max-h-[288px] [&>div>div:nth-child(2)]:max-h-[248px]">
          <TeamSquadTable />
        </div>
      </CardBody>
    </CardHeightWrapper>
  );
};
