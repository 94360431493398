/* TODO (PPC-1782: DRY Season and Team selectors to reuse code with new selectors) */
import { seasonOptionsAtom, selectedSeasonIdsAtom, selectedSeasonsAtom } from '@/atoms/filters/highLevel/seasons';
import { FilterDropdown } from '@/components/dropdowns/FilterDropdown';
import { useDeepEffect } from '@/hooks/useDeepEffect';
import { SelectorProps } from '@/types/filters';
import { convertOptionsToValues } from '@/utils/array';
import { useAtom, useAtomValue } from 'jotai';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';

const SeasonSelectorWithPersistBase = ({ options = [], isLoading }: SelectorProps) => {
  const { t } = useTranslation(['filters', 'entity']);
  const selectedSeasons = useAtomValue(selectedSeasonsAtom);
  const [selectedSeasonIds, setSelectedSeasonIds] = useAtom(selectedSeasonIdsAtom);
  const optionValues = options.map(({ value }) => value);

  useDeepEffect(() => {
    if (!isLoading && selectedSeasonIds) {
      const newSelectedSeasonIds = selectedSeasonIds.filter(seasonId => optionValues.includes(seasonId));
      const hasSelectedSeasons = newSelectedSeasonIds.length > 0;

      /* if the currently selected season still exists in the list after the list of seasons changes
      then persist that season being selected, else select the first option in the list */
      const newSelectedIds = hasSelectedSeasons ? newSelectedSeasonIds : [optionValues[0]];
      setSelectedSeasonIds(newSelectedIds);
    }
  }, [optionValues]);

  return (
    <FilterDropdown
      id="season-multi-select"
      label={t('season.one', { ns: 'entity' })}
      selectedOption={selectedSeasons}
      options={options}
      onChange={options => setSelectedSeasonIds(convertOptionsToValues(options))}
      placeholder={t('dropdownPlaceholder')}
      isLoading={isLoading}
    />
  );
};

const SeasonSelectorWithPersistWithData = () => {
  const seasonOptions = useAtomValue(seasonOptionsAtom);
  return <SeasonSelectorWithPersistBase options={seasonOptions} />;
};

export const SeasonSelectorWithPersist = () => (
  <Suspense fallback={<SeasonSelectorWithPersistBase isLoading />}>
    <SeasonSelectorWithPersistWithData />
  </Suspense>
);
