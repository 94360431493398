import { playerPositionsAndMinutesAtom, unwrappedPlayerPositionsAndMinutesAtom } from '@/atoms/queries/players';
import { CardHeightWrapper } from '@/components/card/CardHeightWrapper';
import { ErrorCard } from '@/components/contentState/ErrorCard';
import { PLAYED_90S_MINIMUM, PLAYED_90S_THRESHOLD } from '@/consts/player';
import { useLoadableStatus } from '@/hooks/useLoadableStatus';
import { usePositionUIElements } from '@/hooks/usePositionUIElements';
import { useStatus } from '@/hooks/useStatus';
import { CardBody, Position } from '@statsbomb/kitbag-components';
import { PitchViz, PositionSelectionLayer } from '@statsbomb/kitbag-datavis';
import { useAtomValue } from 'jotai';
import { useEffect, useState } from 'react';

const PlayerPositionsBase = ({ positions }: { positions: Record<Position, number> }) => {
  const positionUIElements = usePositionUIElements();

  const playerPositions = positionUIElements.map(uiPosition => {
    const played90s = positions[uiPosition.key];

    return {
      ...uiPosition,
      value: played90s?.toFixed(1) || undefined,
      isMuted: !played90s,
      isSelected: played90s > PLAYED_90S_THRESHOLD,
    };
  });

  return <PitchViz className="h-full w-full" overlay={<PositionSelectionLayer playerPositions={playerPositions} />} />;
};

const PlayerPositionsWithData = () => {
  const unwrappedPlayerPositions = useAtomValue(unwrappedPlayerPositionsAndMinutesAtom);
  const { playerPositions, playerTotalMinutes } = unwrappedPlayerPositions;
  const status = useLoadableStatus(playerPositionsAndMinutesAtom);
  const statusProps = useStatus('general', status);
  const [unwrappedPositionsToDisplay, setPositionsToDisplay] = useState(playerPositions);

  // Only update value when minutes data changes, not immediately when the user changes filters
  // This keeps the UI in sync with the debouncing of the filter selections
  useEffect(() => {
    setPositionsToDisplay(playerPositions);
  }, [playerTotalMinutes]);

  const positionsToDisplay = Object.fromEntries(
    Object.entries(unwrappedPositionsToDisplay as Record<string, number>).filter(
      ([_, played90s]) => played90s > PLAYED_90S_MINIMUM,
    ),
  );

  return (
    <CardHeightWrapper>
      <CardBody {...statusProps}>
        <PlayerPositionsBase positions={positionsToDisplay} />
      </CardBody>
    </CardHeightWrapper>
  );
};

export const PlayerPositions = () => (
  <ErrorCard translationNameSpace="general" fallbackChildren={<PlayerPositionsBase positions={{}} />}>
    <PlayerPositionsWithData />
  </ErrorCard>
);
