import { filtersToPersistAtom } from '@/atoms/filters/persistedFilters';
import { itemsPerPageAtom, pageAtom } from '@/atoms/pagination';
import { useAtomValue, useSetAtom } from 'jotai';
import { useDeepEffect } from './useDeepEffect';

export const useResetTablePagination = () => {
  const filters = useAtomValue(filtersToPersistAtom);
  const setActivePage = useSetAtom(pageAtom);
  const itemsPerPage = useAtomValue(itemsPerPageAtom);

  useDeepEffect(() => {
    setActivePage(1);
  }, [itemsPerPage, filters]);
};
