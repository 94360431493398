/* TODO (PPC-1780: Rename current gameSelector to include "WithModal" or similar) */
import { SelectorProps } from '@/types/filters';
import { Suspense, useState } from 'react';
import { useAtomValue } from 'jotai';
import { Button, Typography } from '@statsbomb/kitbag-components';
import { useTranslation } from 'react-i18next';
import { OptionPromiseAtom } from '@/types/atom';
import { useFilteredDeselectedGames } from '@/hooks/useFilteredDeselectedGames';
import { GamesBySeasonPromiseAtom } from '@/types/game';
import { noop } from '@/utils/noop';
import { useGetGameCount } from '@/hooks/useGetGameCount';
import { GameModal } from '../modal/GameModal';

export interface GameSelectorProps {
  gamesGroupedBySeasonAtom: GamesBySeasonPromiseAtom;
  selectedGamesAtom: OptionPromiseAtom;
}

const GameSelectorBase = ({
  setIsModalOpen = noop,
  selectedGameCount,
  isLoading,
}: SelectorProps & {
  setIsModalOpen?: () => void;
  selectedGameCount?: string;
}) => {
  const { t } = useTranslation(['filters', 'entity']);
  return (
    <div>
      <Typography variant="headingMedium">{t('game.one', { ns: 'entity' })}</Typography>
      <div data-testid="game-select" className="flex items-center justify-between">
        <Typography variant="bodyRegular">{isLoading ? '...' : selectedGameCount}</Typography>
        <Button size="small" variant="secondary" onClick={setIsModalOpen} isDisabled={isLoading}>
          {t('select')}
        </Button>
      </div>
    </div>
  );
};

const GameSelectorWithData = ({ gamesGroupedBySeasonAtom, selectedGamesAtom }: GameSelectorProps) => {
  const { t } = useTranslation('filters');
  useFilteredDeselectedGames(gamesGroupedBySeasonAtom);

  const [isGamesModalOpen, setIsGamesModalOpen] = useState(false);
  const formattedGamesBySeason = useAtomValue(gamesGroupedBySeasonAtom);

  const { allSelected, gameCount } = useGetGameCount(selectedGamesAtom, gamesGroupedBySeasonAtom);
  const selectedGamesCount = allSelected ? t('allSelected') : t('countSelected', { count: gameCount });

  return (
    <>
      <GameSelectorBase setIsModalOpen={() => setIsGamesModalOpen(true)} selectedGameCount={selectedGamesCount} />
      {isGamesModalOpen && (
        <GameModal
          gamesBySeason={formattedGamesBySeason}
          setIsModalOpen={setIsGamesModalOpen}
          selectedGamesAtom={selectedGamesAtom}
        />
      )}
    </>
  );
};

export const GameSelector = ({ gamesGroupedBySeasonAtom, selectedGamesAtom }: GameSelectorProps) => (
  <Suspense fallback={<GameSelectorBase isLoading />}>
    <GameSelectorWithData gamesGroupedBySeasonAtom={gamesGroupedBySeasonAtom} selectedGamesAtom={selectedGamesAtom} />
  </Suspense>
);
