import { atom } from 'jotai';
import {
  GOALKEEPER_GAMES_DEFAULT_COLUMNS,
  PLAYER_GAMES_DEFAULT_COLUMNS,
} from '@/components/tables/GameAggregatesTable.constants';
import { playerGameAggsPositionsAtom } from '@/atoms/queries/player/playerGameAggData';
import { Position } from '@/types/player';
import { unwrap } from 'jotai/utils';
import { TableId } from '@/types/table';

export const isPlayerGkAtom = atom(async get => {
  // positions are already sorted in a descending order from most played position to least played position
  const { data: playerGameAggsPositions } = await get(playerGameAggsPositionsAtom);
  return playerGameAggsPositions[0]?.position === Position.GK;
});

export const playerGameDataDefaultColumnsAtom = atom(async get => {
  const isPlayerGk = await get(isPlayerGkAtom);

  return [...(isPlayerGk ? GOALKEEPER_GAMES_DEFAULT_COLUMNS : PLAYER_GAMES_DEFAULT_COLUMNS)];
});
export const unwrappedPlayerGameDataDefaultColumnsAtom = unwrap(playerGameDataDefaultColumnsAtom, prev => prev || []);

export const playerGameDataTableIdAtom = atom(async get => {
  const isPlayerGk = await get(isPlayerGkAtom);
  return isPlayerGk ? TableId.PLAYER_GAMES_GK : TableId.PLAYER_GAMES_OUTFIELD;
});
