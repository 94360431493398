import { UserConfigWidgetDefinition } from '@/types/userConfigs';
import { Entity } from '@/types/entity';
import { dashboardTemplatesAtom, dashboardWidgetsAtom } from '@/atoms/queries/userConfigs';
import { Getter } from 'jotai';

// get a list of all the widget configs that are referenced in any dashboard template of a given entity
export const getDashboardTemplateWidgetsByEntity = async (
  get: Getter,
  // TODO (PPC-1784: https://statsbomb.atlassian.net/browse/PPC-1784)
  entity: Entity | 'game',
) => {
  const dashboardTemplates = await get(dashboardTemplatesAtom);
  const dashboardWidgets = await get(dashboardWidgetsAtom);

  const entityDashboardWidgetIds = dashboardTemplates
    .filter(dashboardTemplate => dashboardTemplate.definition.entity === entity)
    .flatMap(entityDashboardTemplates => entityDashboardTemplates.definition.widgetIds);

  const entityDashboardWidgets = entityDashboardWidgetIds
    .map(widgetId => dashboardWidgets.find(widget => widget.configId === widgetId))
    .filter((widget): widget is UserConfigWidgetDefinition => Boolean(widget));

  return entityDashboardWidgets;
};
