import { tableColumnsEditorActiveTabAtom } from '@/atoms/table';
import { TableColumnsEditorTabs } from '@/types/table';
import { Tab } from '@/types/tabs';
import { Tabs } from '@statsbomb/kitbag-components';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

export const MobileTableColumnsEditorTabs = () => {
  const { t } = useTranslation('table');
  const [activeTab, setActiveTab] = useAtom(tableColumnsEditorActiveTabAtom);

  const onTabChange = (_: React.MouseEvent<HTMLElement>, { value }: Tab<TableColumnsEditorTabs>) => setActiveTab(value);

  const tabs = [
    { value: TableColumnsEditorTabs.COLUMN_SELECT, label: t('availableColumns') },
    { value: TableColumnsEditorTabs.COLUMN_SORT, label: t('selectedColumns') },
  ];

  return (
    <div className="md:hidden">
      <Tabs tabs={tabs} activeTab={activeTab} onTabChange={onTabChange} />
    </div>
  );
};
