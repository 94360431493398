import { ITEMS_PER_PAGE } from '@/consts/interface';
import { TableColumn } from '@statsbomb/parachute-types';
import { KeysToCamelCase } from './generic';

export type DataTableColumn = KeysToCamelCase<TableColumn>;

export type ColumnGroup = {
  header: {
    translationKey: string;
    translationNs: string;
  };
  options: {
    value: string;
    label: {
      translationKey: string;
      translationNs: string;
    };
  }[];
};

export type ItemsPerPage = (typeof ITEMS_PER_PAGE)[number];

export enum TableColumnsEditorTabs {
  COLUMN_SELECT = 'column-select',
  COLUMN_SORT = 'column-sort',
}

// Table IDs to assign to tables. These are saved to the config when the user edits/saves table columns
export enum TableId {
  PLAYER_AGGS = 'playerAggs',
  PLAYER_GAMES_GK = 'playerGamesGK',
  PLAYER_GAMES_OUTFIELD = 'playerGamesOutfield',
  PLAYER_EVENTS = 'playerEvents',
  TEAM_AGGS = 'teamAggs',
  TEAM_GAMES = 'teamGames',
  TEAM_EVENTS = 'teamEvents',
  TEAM_SQUAD = 'teamSquad',
  EVENT_DATA = 'eventData',
}
