import { CompetitionSelector } from './CompetitionSelector';
import { DateRangeSelector } from './DateRangeSelector';
import { PlayerSelector } from './PlayerSelector';
import { SeasonSelector } from './SeasonSelector';
import { TeamSelector } from './TeamSelector';

export const PlayerDataLockerGeneralFilters = () => (
  <>
    <CompetitionSelector isMulti />
    <SeasonSelector />
    <TeamSelector />
    <PlayerSelector />
    <DateRangeSelector />
  </>
);
