import { useTranslation } from 'react-i18next';

export const useEntityPageTitle = (page: string, entityName: string) => {
  const { t } = useTranslation('navigation');
  /* 
    Doing this as per the i18next translation docs to escape the value before translating
    Built in i18next escaping results in things like & being output as &amp
    Instead, wrapping text as a string literal to escape it
    https://www.i18next.com/translation-function/interpolation#unescape
  */
  return t('pageTab', { interpolation: { escapeValue: false }, entityName, page });
};
