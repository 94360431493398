import { tableColumnGroupsAtom } from '@/atoms/queries/tableColumns';
import { Button, Modal, ModalFooter, ModalHeader } from '@statsbomb/kitbag-components';
import { useAtom, useAtomValue } from 'jotai';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { defaultTableColumnsAtom, visibleTableColumnsAtom, tableConfigAtom, tableIdAtom } from '@/atoms/table';
import { useUpsertUserConfig } from '@/hooks/useUpsertUserConfig';
import { useDeleteUserConfig } from '@/hooks/useDeleteUserConfig';
import { ColumnSelect } from './ColumnSelect';
import { ColumnSort } from './ColumnSort';
import { MobileTableColumnsEditorTabs } from './MobileTableColumnsEditorTabs';

export const EditTableColumnsModal = ({
  setShowEditColumnsModal,
}: {
  setShowEditColumnsModal: Dispatch<SetStateAction<boolean>>;
}) => {
  const { t } = useTranslation(['table', 'general']);

  const columnGroups = useAtomValue(tableColumnGroupsAtom);
  const [tableVisibleColumns, setTableVisibleColumns] = useAtom(visibleTableColumnsAtom);
  const [modalVisibleColumns, setModalVisibleColumns] = useState(tableVisibleColumns);
  const defaultColumns = useAtomValue(defaultTableColumnsAtom);
  const tableId = useAtomValue(tableIdAtom);
  const { configId } = useAtomValue(tableConfigAtom);
  const upsertUserConfig = useUpsertUserConfig();
  const deleteUserConfig = useDeleteUserConfig();

  const handleCancelModal = () => {
    setModalVisibleColumns(tableVisibleColumns);
    setShowEditColumnsModal(false);
  };

  const handleConfirmModal = () => {
    setTableVisibleColumns(modalVisibleColumns);
    setShowEditColumnsModal(false);

    if (tableId) {
      const defaultColumnsSelected = defaultColumns.toString() === modalVisibleColumns.toString();

      // In this function, we don't want to save the defaults to the DB as these will be the defaults for that moment in time.
      // By not saving or deleting these, should the table's default columns change the user will always see the up-to-date defaults.

      // Delete configs which match the table defaults
      if (configId && defaultColumnsSelected) {
        deleteUserConfig(configId);
        return;
      }

      // Do nothing if user has no saved config for this table and has selected the defaults
      if (defaultColumnsSelected) return;

      // Save user selection
      upsertUserConfig({
        configId,
        configType: 'tableColumns',
        configDefinition: { tableId, selectedColumns: modalVisibleColumns },
      });
    }
  };

  return (
    <Modal id="modal-edit-columns" isOpen onCancel={handleCancelModal} onConfirm={handleConfirmModal}>
      <ModalHeader id="modal-edit-columns">{t('editColumns')}</ModalHeader>
      <MobileTableColumnsEditorTabs />
      <div className="flex w-[320px] md:w-[684px] h-[420px]">
        <ColumnSelect
          columnGroups={columnGroups}
          visibleColumns={modalVisibleColumns}
          setVisibleColumns={setModalVisibleColumns}
        />
        <ColumnSort
          visibleColumns={modalVisibleColumns}
          setVisibleColumns={setModalVisibleColumns}
          defaultColumns={defaultColumns}
        />
      </div>
      <ModalFooter>
        <Button onClick={handleCancelModal} variant="ghost">
          {t('cancel', { ns: 'general' })}
        </Button>
        <Button onClick={handleConfirmModal} variant="primary" isDisabled={!modalVisibleColumns.length}>
          {t('apply', { ns: 'general' })}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
