import { ButtonIcon, Card, CardBody } from '@statsbomb/kitbag-components';
import { useTranslation } from 'react-i18next';

export const PlayerDataLockerMetricFilters = () => {
  const { t } = useTranslation('filters');

  return (
    <div className="h-full flex items-center min-h-36">
      <div className="w-full">
        <Card>
          <CardBody
            statusType="info"
            statusTitle={t('metricInfo.title')}
            statusDescription={t('metricInfo.description')}
          />
          <div className="flex justify-center mt-8">
            <ButtonIcon icon="PlusAdd" size="small" variant="secondary" displayText="left">
              {t('addMetricFilter')}
            </ButtonIcon>
          </div>
        </Card>
      </div>
    </div>
  );
};
