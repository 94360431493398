import {
  competitionOptionsAtom,
  selectedCompetitionIdsAtom,
  selectedCompetitionsAtom,
} from '@/atoms/filters/highLevel/competitions';
import { FilterDropdown } from '@/components/dropdowns/FilterDropdown';
import { SelectorProps } from '@/types/filters';
import { convertOptionsToValues } from '@/utils/array';
import { useAtom, useAtomValue } from 'jotai';
import { Suspense, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const CompetitionSelectorBase = ({ options = [], isLoading, isMulti }: SelectorProps) => {
  const { t } = useTranslation(['filters', 'entity']);
  const selectedCompetitions = useAtomValue(selectedCompetitionsAtom);
  const [selectedCompetitionIds, setSelectedCompetitionIds] = useAtom(selectedCompetitionIdsAtom);
  const optionValues = options?.map(({ value }) => value);

  useEffect(() => {
    if (!isLoading && selectedCompetitionIds) {
      const newSelectedCompetitionIds = selectedCompetitionIds.filter(
        competitionId => optionValues?.includes(competitionId),
      );
      setSelectedCompetitionIds(newSelectedCompetitionIds);
    }
  }, [JSON.stringify(optionValues)]);

  return (
    <FilterDropdown
      id="competition-multi-select"
      label={t('competition.one', { ns: 'entity' })}
      selectedOption={selectedCompetitions}
      options={options}
      onChange={options => setSelectedCompetitionIds(convertOptionsToValues(options))}
      placeholder={t('dropdownPlaceholder')}
      isLoading={isLoading}
      isMulti={isMulti}
    />
  );
};

const CompetitionSelectorWithData = ({ isMulti }: { isMulti: boolean }) => {
  const competitionOptions = useAtomValue(competitionOptionsAtom);
  return <CompetitionSelectorBase options={competitionOptions} isMulti={isMulti} />;
};

export const CompetitionSelector = ({ isMulti = false }: { isMulti?: boolean }) => (
  <Suspense fallback={<CompetitionSelectorBase isLoading isMulti={isMulti} />}>
    <CompetitionSelectorWithData isMulti={isMulti} />
  </Suspense>
);
