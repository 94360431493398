import { VideoProvider, Playlist, VideoEvent } from '@statsbomb/video-client-js';
import { SelectedVideoIds } from '@/types/video';
import { Nullable } from '@/types/generic';
import { useFormatVideoEvents } from '@/hooks/useFormatVideoEvents';
import { EventDescription } from '@/types/event';
import { WideFirstColumnLayout } from '../WideFirstColumnLayout';

export const GameStatsVideoLayout = ({
  videoEventIds,
  descriptionsData,
}: {
  videoEventIds: Nullable<SelectedVideoIds[]>;
  descriptionsData: EventDescription[];
}) => {
  const videoEvents = useFormatVideoEvents(videoEventIds, descriptionsData);

  return (
    <div className="h-full video-column-order [&>div]:h-full [&_[data-column='1']>div]:pt-0">
      <VideoProvider>
        <WideFirstColumnLayout>
          <Playlist enableEditMode enableSelectAll />
          <VideoEvent autoPlay events={videoEvents} />
        </WideFirstColumnLayout>
      </VideoProvider>
    </div>
  );
};
