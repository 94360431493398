import { FilterDropdown } from '@/components/dropdowns/FilterDropdown';
import { useTranslation } from 'react-i18next';
import { useAtomValue, useSetAtom } from 'jotai';
import { SelectorProps } from '@/types/filters';
import { Suspense, useEffect } from 'react';
import { selectedTeamsAtom, teamOptionsAtom, selectedTeamIdsAtom } from '@/atoms/filters/highLevel/teams';
import { convertOptionsToValues } from '@/utils/array';

const TeamSelectorBase = ({ options, isLoading }: SelectorProps) => {
  const { t } = useTranslation(['filters', 'entity']);
  const selectedTeams = useAtomValue(selectedTeamsAtom);
  const setSelectedTeamIds = useSetAtom(selectedTeamIdsAtom);

  const optionValues = options?.map(({ value }) => value);
  useEffect(() => {
    if (!isLoading) {
      const newSelectedTeamIds = selectedTeams
        .map(({ value }) => value)
        .filter(teamId => optionValues?.includes(teamId));
      setSelectedTeamIds(newSelectedTeamIds);
    }
  }, [JSON.stringify(optionValues)]);

  return (
    <FilterDropdown
      id="team-multi-select"
      label={t('team.one', { ns: 'entity' })}
      selectedOption={selectedTeams}
      options={options}
      onChange={options => setSelectedTeamIds(convertOptionsToValues(options))}
      placeholder={t('dropdownPlaceholder')}
      isLoading={isLoading}
      isMulti
    />
  );
};

const TeamSelectorWithData = () => {
  const teamOptions = useAtomValue(teamOptionsAtom);

  return <TeamSelectorBase options={teamOptions} />;
};

export const TeamSelector = () => (
  <Suspense fallback={<TeamSelectorBase isLoading />}>
    <TeamSelectorWithData />
  </Suspense>
);
